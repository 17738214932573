import React from "react";

function Footer() {
  return (
    <footer>
    <div>
        <p>Created by Andris Rībens</p>
    </div>  
    </footer>
  );
}

export default Footer;
